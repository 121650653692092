import Typography from "typography";

const typography = new Typography({
  scaleRatio: 2,
  headerFontFamily: ["Helvetica", "sans-serif"],
  bodyFontFamily: ["Helvetica", "sans-serif"],
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
