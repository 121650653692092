import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm } from "../utils/typography";

export default class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Blog" />
        <h1 className="MainHeading">
          Hey, I'm Alex.
          <br />
          This is my website.
        </h1>
        {posts.map(({ node }) => {
          const { draft } = node.frontmatter;
          if (process.env.NODE_ENV === "production" && draft) {
            return null;
          }
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <Link
              key={node.fields.slug}
              to={node.fields.slug}
              className="ArticlePreview"
              style={{ marginBottom: rhythm(0.5) }}
            >
              <h2>
                {process.env.NODE_ENV === "development" && draft ? (
                  <span style={{ color: "#3fe83f" }}>{title} [DRAFT]</span>
                ) : (
                  title
                )}
              </h2>
              <span className="ArticlePreviewDate">
                {node.frontmatter.date}
              </span>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.subtitle || node.excerpt,
                }}
              />
            </Link>
          );
        })}
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            draft
            title
            subtitle
          }
        }
      }
    }
  }
`;
