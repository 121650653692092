import React from "react";
import { Link } from "gatsby";

import { rhythm } from "../utils/typography";

import "./prism-theme.css";
import "./layout.css";

export default function Layout(props) {
  const { children } = props;

  return (
    <div className="LayoutContainer">
      <header>
        <div className="ContentContainer CodeFont">
          <Link
            style={{
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
            alt="Home"
          >
            > <span style={{ color: "white" }}>heyimalex</span>
          </Link>
        </div>
      </header>
      <main
        className="ContentContainer"
        style={{
          paddingTop: rhythm(1.5),
          paddingBottom: rhythm(1.5),
        }}
      >
        {children}
      </main>
      <footer>
        <div className="ContentContainer CodeFont">
          hey im <strong>Alex Guerra</strong>
          <br />
          follow me on the internet
          <br />
          <a href="https://github.com/heyimalex">
            <strong>github</strong> — heyimalex
          </a>
          <br />
          <a href="https://twitter.com/yehmixela">
            <strong>twitter</strong> — yehmixela
          </a>
          <br />
          <a href="https://heyimalex.com">
            <strong>heyimalex.com</strong>
          </a>
        </div>
      </footer>
    </div>
  );
}
